@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?osg0hu');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?osg0hu#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?osg0hu') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?osg0hu') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?osg0hu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ProViz_Studio_Header_Logo {
  &:before {
    content: $icon-ProViz_Studio_Header_Logo; 
  }
}
.icon-translate {
  &:before {
    content: $icon-translate;     
    color: #47538c;
  }
}
.icon-share {
  &:before {
    content: $icon-share;     
    color: #47538c;
  }
}
.icon-downArrow {
  &:before {
    content: $icon-downArrow; 
  }
}
.icon-slash {
  &:before {
    content: $icon-slash; 
  }
}
.icon-scale {
  &:before {
    content: $icon-scale; 
  }
}
.icon-rotate {
  &:before {
    content: $icon-rotate; 
  }
}
.icon-finishes {
  &:before {
    content: $icon-finishes;     
    color: #47538c;
  }
}
.icon-background {
  &:before {
    content: $icon-background;     
    color: #47538c;
  }
}
.icon-transform {
  &:before {
    content: $icon-transform;     
    color: #47538c;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;     
    color: #96989b;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;     
    color: #96989b;
  }
}
.icon-trash_grey {
  &:before {
    content: $icon-trash_grey;     
    color: #808184;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;     
    color: #808184;
  }
}
.icon-outlined_star {
  &:before {
    content: $icon-outlined_star;     
    color: #808184;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;     
    color: #808184;
  }
}
.icon-open {
  &:before {
    content: $icon-open;     
    color: #808184;
  }
}
.icon-cancel .path1 {
  &:before {
    content: $icon-cancel-path1;  
    color: rgb(128, 128, 128);
  }
}
.icon-cancel .path2 {
  &:before {
    content: $icon-cancel-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-cancel .path3 {
  &:before {
    content: $icon-cancel-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-yellow_star {
  &:before {
    content: $icon-yellow_star;     
    color: #ffaf47;
  }
}
.icon-folder-icon {
  &:before {
    content: $icon-folder-icon;     
    color: #808080;
  }
}
.icon-hand-icon {
  &:before {
    content: $icon-hand-icon;     
    color: #47538c;
  }
}
.icon-object-icon {
  &:before {
    content: $icon-object-icon;     
    color: #808080;
  }
}
.icon-presentation-icon {
  &:before {
    content: $icon-presentation-icon;     
    color: #47538c;
  }
}
.icon-ProViz-Studio-Assets-66 {
  &:before {
    content: $icon-ProViz-Studio-Assets-66;     
    color: #47538c;
  }
}
.icon-ProViz-Studio-Assets-67 {
  &:before {
    content: $icon-ProViz-Studio-Assets-67;     
    color: #47538c;
  }
}
.icon-ProViz-Studio-Assets-68 {
  &:before {
    content: $icon-ProViz-Studio-Assets-68;     
    color: #808080;
  }
}
.icon-eye-2-icon {
  &:before {
    content: $icon-eye-2-icon;     
    color: #808080;
  }
}
.icon-eye-icon {
  &:before {
    content: $icon-eye-icon;     
    color: #808080;
  }
}
.icon-locked-icon {
  &:before {
    content: $icon-locked-icon;     
    color: #808080;
  }
}
.icon-ProViz-Studio-Assets-58 {
  &:before {
    content: $icon-ProViz-Studio-Assets-58;     
    color: #808080;
  }
}
.icon-ProViz-Studio-Assets-41 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-41 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path2;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-41 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path3;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-41 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path4;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-41 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path5;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-41 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-41-path6;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-42 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-42 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path2;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-42 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path3;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-42 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path4;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-42 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-42 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path6;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-42 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path7;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-42 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path8;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-42 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path9;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-42 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-42-path10;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-43 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path1;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-43 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path2;  
    margin-left: -1.0009765625em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-43 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path3;  
    margin-left: -1.0009765625em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-43 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path4;  
    margin-left: -1.0009765625em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-43 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path5;  
    margin-left: -1.0009765625em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-43 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path6;  
    margin-left: -1.0009765625em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-43 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path7;  
    margin-left: -1.0009765625em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-43 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-43-path8;  
    margin-left: -1.0009765625em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-17 {
  &:before {
    content: $icon-ProViz-Studio-Assets-17;     
    color: #808184;
  }
}
.icon-ProViz-Studio-Assets-21 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-21-path1;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-21 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-21-path2;  
    margin-left: -1em;  
    color: rgb(250, 251, 251);
  }
}
.icon-ProViz-Studio-Assets-22 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path1;  
    color: rgb(244, 243, 238);  
    opacity: 0.59;
  }
}
.icon-ProViz-Studio-Assets-22 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path3;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path4;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path5;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path7;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path8;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path9;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-22 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path11;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-22 .path12 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path13 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path13;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-22 .path14 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path14;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-22 .path15 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path16 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path16;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-22 .path17 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-22 .path18 {
  &:before {
    content: $icon-ProViz-Studio-Assets-22-path18;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-23 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path1;  
    color: rgb(244, 243, 238);  
    opacity: 0.59;
  }
}
.icon-ProViz-Studio-Assets-23 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-23 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path3;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-23 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path4;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-23 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path5;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-23 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-23 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path7;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-23 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path8;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-23 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-23-path9;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path1;  
    color: rgb(244, 243, 238);  
    opacity: 0.59;
  }
}
.icon-ProViz-Studio-Assets-24 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-24 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path3;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path4;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path5;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-24 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path7;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path8;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path9;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path10;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-24 .path11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path11;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-24 .path12 {
  &:before {
    content: $icon-ProViz-Studio-Assets-24-path12;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path1;  
    color: rgb(244, 243, 238);  
    opacity: 0.59;
  }
}
.icon-ProViz-Studio-Assets-25 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-25 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path3;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path4;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path5;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-25 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path7;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path8;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path9;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-25 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path10;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-25 .path11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path11;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-25 .path12 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path12;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-25 .path13 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path13;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-25 .path14 {
  &:before {
    content: $icon-ProViz-Studio-Assets-25-path14;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-26 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path1;  
    color: rgb(244, 243, 238);  
    opacity: 0.59;
  }
}
.icon-ProViz-Studio-Assets-26 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-26 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path3;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path4;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path5;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-26 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path7;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path8;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path9;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path10;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-26 .path11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path11;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-26 .path12 {
  &:before {
    content: $icon-ProViz-Studio-Assets-26-path12;  
    margin-left: -1em;  
    color: rgb(71, 83, 140);
  }
}
.icon-ProViz-Studio-Assets-27 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path1;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-27 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path2;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-27 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path3;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-27 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path4;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-27 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-27 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-27-path6;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-28 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-28 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path2;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-28 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path3;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-28 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path4;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-28 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path5;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-28 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path6;  
    margin-left: -1em;  
    color: rgb(113, 250, 181);
  }
}
.icon-ProViz-Studio-Assets-28 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path7;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-28 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-28-path8;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-30 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path2;  
    margin-left: -1.0009765625em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-30 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path3;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path4;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path5;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path6;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path7;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-30 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-30-path8;  
    margin-left: -1.0009765625em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-31 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-31 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-31 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-31 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path4;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-31 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-31 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path6;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-31 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path7;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-31 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-31-path8;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-32 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path1;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-32 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path2;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-32 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path3;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-32 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path4;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-32 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-32 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-32 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path7;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-32 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-32-path8;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-33 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path2;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-33 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path3;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path4;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path6;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path7;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-33 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-33-path8;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-34 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-34 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path2;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-34 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path3;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-34 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-34 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-34 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-34 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-34 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-34-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-35 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-35-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-35 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-35-path2;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-35 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-35-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-35 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-35-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-35 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-35-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-36 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-36 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path2;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-36 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path3;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-36 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path4;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-36 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path5;  
    margin-left: -1em;  
    color: rgb(166, 171, 200);
  }
}
.icon-ProViz-Studio-Assets-36 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path6;  
    margin-left: -1em;  
    color: rgb(108, 117, 163);
  }
}
.icon-ProViz-Studio-Assets-36 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-36-path7;  
    margin-left: -1em;  
    color: rgb(240, 241, 241);
  }
}
.icon-ProViz-Studio-Assets-37 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-37 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-37 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-37 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path4;  
    margin-left: -1em;  
    color: rgb(70, 80, 144);
  }
}
.icon-ProViz-Studio-Assets-37 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path5;  
    margin-left: -1em;  
    color: rgb(107, 115, 166);
  }
}
.icon-ProViz-Studio-Assets-37 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path6;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-37 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-37-path7;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-38 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-38 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-38 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-38 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path4;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-38 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path5;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-38 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path6;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-38 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path7;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-38 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path8;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-38 .path9 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path9;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-38 .path10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path10;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-38 .path11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-38-path11;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-39 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path1;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-39 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path2;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-39 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path3;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-39 .path4 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path4;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-39 .path5 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path5;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-39 .path6 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path6;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-39 .path7 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path7;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-ProViz-Studio-Assets-39 .path8 {
  &:before {
    content: $icon-ProViz-Studio-Assets-39-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-40 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-40-path1;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-40 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-40-path2;  
    margin-left: -1em;  
    color: rgb(69, 212, 245);
  }
}
.icon-ProViz-Studio-Assets-40 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-40-path3;  
    margin-left: -1em;  
    color: rgb(77, 249, 163);
  }
}
.icon-share1 {
  &:before {
    content: $icon-share1;     
    color: #808184;
  }
}
.icon-ProViz-Studio-Assets-02 {
  &:before {
    content: $icon-ProViz-Studio-Assets-02; 
  }
}
.icon-ProViz-Studio-Assets-03 {
  &:before {
    content: $icon-ProViz-Studio-Assets-03; 
  }
}
.icon-ProViz-Studio-Assets-04 {
  &:before {
    content: $icon-ProViz-Studio-Assets-04; 
  }
}
.icon-ProViz-Studio-Assets-05 {
  &:before {
    content: $icon-ProViz-Studio-Assets-05;     
    color: #47538c;
  }
}
.icon-ProViz-Studio-Assets-06 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-06-path1;  
    color: rgb(128, 128, 128);
  }
}
.icon-ProViz-Studio-Assets-06 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-06-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-06 .path3 {
  &:before {
    content: $icon-ProViz-Studio-Assets-06-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-07 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-07-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-07 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-07-path2;  
    margin-left: -1em;  
    color: rgb(48, 61, 101);
  }
}
.icon-ProViz-Studio-Assets-08 .path1 {
  &:before {
    content: $icon-ProViz-Studio-Assets-08-path1;  
    color: rgb(65, 251, 160);
  }
}
.icon-ProViz-Studio-Assets-08 .path2 {
  &:before {
    content: $icon-ProViz-Studio-Assets-08-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ProViz-Studio-Assets-09 {
  &:before {
    content: $icon-ProViz-Studio-Assets-09;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-10 {
  &:before {
    content: $icon-ProViz-Studio-Assets-10;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-11 {
  &:before {
    content: $icon-ProViz-Studio-Assets-11;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-12 {
  &:before {
    content: $icon-ProViz-Studio-Assets-12;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-13 {
  &:before {
    content: $icon-ProViz-Studio-Assets-13;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-14 {
  &:before {
    content: $icon-ProViz-Studio-Assets-14;     
    color: #45d4f5;
  }
}
.icon-ProViz-Studio-Assets-15 {
  &:before {
    content: $icon-ProViz-Studio-Assets-15;     
    color: #bbbdbf;
  }
}
.icon-ProViz-Studio-Assets-16 {
  &:before {
    content: $icon-ProViz-Studio-Assets-16;     
    color: #808184;
  }
}
.icon-person {
  &:before {
    content: $icon-person; 
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}

