
button.saveButton.saving, button.saveButton.publishing {
    background-color: $light-blue;
}

button.saveButton.failed {
    background-color: red;
}

button.saveButton.ok{
    background: $green-one;
}