@import './fonts/_poppins.scss';

html,body { height:100%; }
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/*** Hiding iframes directly under the body, it's causing issues when hot-reloading ***/
body > iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {  height: 100%;}

// // this needs to be first so colors are accessible in all other imports 
@import './_colors.scss';

select {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

// this needs to be second so font classnames are accessible in all other imports 
@import './fonts/_icomoon.scss';

@import './components/common/_index.scss';

@import './components/home/_home.scss';

@import './components/settings/_settings.scss';

@import './components/modeleditor/_modeleditor.scss';

@import './components/sceneeditor/_sceneeditor.scss';
