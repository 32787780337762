.proviz-dropzone {
    display: grid;
    place-content: center;
    height: 300px;
    width: 100%;
    cursor: pointer;
    background: $light-gray;
    border: 6px dashed #313E64;
    border-radius: 30px;

    img {
        height: 200px;
        width: 200px;
    }

    h2 {
        color: $dark-purple;
        text-align: center;
    }
}

.proviz-dropzone.small {
    height: 100px;
    border: 4px dashed #313E64;
    img {
        height: 75px;
        width: 75px;
    }
}

.proviz-dropzone.active {
    background: $light-blue;
}

.proviz-dropzone.hide {
    display: none;
}

.upload {
    padding-left: 120px;
}

.proviz-dropzone-small {
    height: 0px;
    width: auto;
    border: none;
    margin-right: 20px;

    button {
        display: block;
    }
}

.dropzone-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    // margin-left: 30px;
}

.remove {
    border: 1px solid #313E64;
    border-radius: 50px;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    text-align: center;
    position: relative;
    top: -2px;
    cursor: pointer;
    margin-left: 10px;
}
