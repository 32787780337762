.infobutton-container {
  position: relative;

  .info-hover-box {
    position: absolute;
    left: -250px;
    z-index: 101;
    background: #fff;
    border-radius: 0;
    font-size: small;
    margin-right: 0.8rem;
    padding-left: 0.8rem;
  }
}
.infoButton {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 5rem;
  background: #fff;
  color: #000;
  cursor: pointer;
  margin: 3px;
}
