.menus {
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    align-items: center;
    background: #2c2c2e;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
    height: 2.6rem;
    margin: 0.6rem;
    margin-bottom: 0.6rem;
    cursor: pointer;
    justify-content: space-between;
  }
}

.categoryIcon {
  margin-left: 0.4rem;
}
.widgetGroupName {
  color: #ffffff;
}
.widgetName {
  color: #ffffff;
  margin-left: 0.4rem;
}

.arrowIcon {
  float: right;
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  margin-right: 0.2rem;
}
