.hover-dropdown-container.share-button {
    h1 {
        font-weight: 500;
        line-height: 0;
        color: $dark-purple;
    }
    .dropdown-panel {
        position: absolute;
        display: flex;
        top: 55px;
        right: 0;
        width: 320px;
        border: 1px solid $mid-purple;
        padding: 5px;
        border-radius: 5px;
        background: #fff;
        flex-direction: column;
        div.qrContainer {
            display: grid;
            place-items: center;
            img {
                height: 250px;
                width: 250px;
                border: none;
                margin-bottom: 0;
            }
        }
    }
}

.code {
    padding: 10px 15px;
    background: $mid-gray;
    font-size: .8em;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

div.editableTitle {
    .nameInputQR {
        input {
            margin: 0;
            padding: 0;
            color: #000;
        }
    }
}