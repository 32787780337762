.uploadResult {
  h3 {
    font-weight: 400;
    line-height: 0.3rem;
    padding-left: 5px;
    color: $dark-purple;
  }
}

.uploadFileList {
  li {
    list-style: none;
    display: grid;
    grid-template-columns: auto 1fr;

    img.checkmark {
      height: 15px;
      width: 15px;
    }
  }
}

.spacedContainer {
  display: flex;
  place-content: space-between;
  width: 100%;
}

.flex {
  display: flex;
}

p .normalHeight {
  line-height: normal;
}

.tabbedUploadArea {
  .tabBar {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    .tabTitle {
      background: $mid-purple;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: grid;
      place-content: center;
      // height: 50px;
    }
    .tabTitle.selected {
      background: $light-blue;
    }
  }

  .uploadTab {
    background: $light-gray;
    .proviz-dropzone {
      height: 100px;
      img {
        height: 30px;
      }
      h2 {
        border: none;
        padding-bottom: 0px;
      }
    }
  }
  .uploadTab.hidden {
    display: none;
  }
}

.emmisiveFactorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
}

.mpGroupHeader {
  margin-top: 10px;
  display: inline-flex;
  place-content: space-between;
  width: 100%;

  h3 {
    color: $mid-purple;
    font-weight: 450;
    padding-bottom: 5px;
    line-height: 0;
  }
  img {
    margin-right: 5px;
  }
  border-bottom: 1px solid $mid-purple;
}

.materialSelector,
.variantSelector {
  border: 1px solid $mid-purple;
  max-height: 150px;
  padding-left: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  li {
    color: $dark-purple;
    list-style: none;
    padding-left: 3px;
    cursor: pointer;
  }
  li.selected {
    background: $mid-purple;
    color: $light-blue;
    p {
      color: $light-blue;
    }
  }
  li:hover {
    background: $light-blue;
  }
  li.selected:hover {
    background: $mid-purple;
    color: $light-blue;
  }
  li.variantSelectorEntry {
    display: grid;
    grid-template-columns: auto 1fr auto;

    span {
      margin: 5px;
    }

    span:hover {
      background: $green-one;
    }

    .buttonContainer {
      display: flex;
    }
    span.icon-outlined_star,
    span.icon-yellow_star,
    span.icon-ProViz-Studio-Assets-12 {
      &::before {
        color: $mid-purple;
      }
    }
  }

  li.variantSelectorEntry.selected {
    span.icon-outlined_star,
    span.icon-yellow_star,
    span.icon-ProViz-Studio-Assets-12 {
      &:before {
        color: $light-blue;
      }
    }
  }
}

.materialSetName {
  input {
    border: none;
    font-size: large;
    font-weight: 300;
    color: $dark-purple;
    margin-left: 10px;
  }
}

.variantInfo {
  .infobutton-container {
    align-self: center;
  }
}
.collapsible-properties-header {
  display: inline-flex;
  place-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $dark-purple;

  img {
    margin-top: 0px;
    margin-right: 5px;
  }
}
h5 {
  margin: 0px;
  margin-top: 15px;
  width: 100%;
  box-shadow: #eee 0px 15px 20px 0;
  text-align: center;
  font-weight: 300;
  color: $mid-purple;
  font-size: large;
}

.info-table {
  margin-top: 1rem;
}
