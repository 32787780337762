.nodeSelector {
    overflow: auto;
    background: #fff;
    .flow-icon {
        height: 17px;
        width: 17px;
        cursor: pointer;
    }
    .flow-icon.selected {
        background: $mid-gray;
    }
    .nodeSelectIndex {
        height: 5px;
        width: 100%;
        z-index: 1000;
        &.drop {
            background: #ccc;
        }
    }
    .nodeSelectorEntry {
        flex: 1 1 auto;
        min-height: 30px;
        // height: 100%;
        &.hidden, .hasChildSearchMatch {
            opacity: 0.5;

            .nodeSelectorEntry.hidden { opacity: 1.0; }
        }
        .nodeSelectEntity {
            padding: 0 1px;
            height: fit-content;
            margin: 0;
            // padding-left: 15px;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto auto 1fr auto auto;
            div {
                display: inline;
                padding-left: 5px;
            }
            .node-label.hidden {
                color: #ccc;
            }
            &:hover {
                background: $light-gray;
            }
            img.widget-icon {
                height: 15px;
                padding: 4px 2px;
                padding-left: 0;
                max-width: 100%;
            }
            .widget-icon-container {
                width: 1rem;
                height: 1.5rem;
            }
        }
        .nodeSelectEntity.cannotReposition {
            background: $mid-gray;
        }
        .nodeSelectEntity.indent {
            grid-template-columns: 1.125rem 1.125rem 1fr auto auto auto auto;
        }
        .nodeSelectEntity .icon {
            display: none;
        }

        .nodeSelectEntity:hover .icon {
            display: inline-block;
        }
        .tree-control {
            font-size: 2rem;
            padding: 0;
            margin: 0;
            padding-right: 2px;
            line-height: 1rem;
            color: $dark-purple;
            width: 1rem;
            height: 1.5rem;
        }
        .nodeSelectEntity.selected {
            background: lighten($dark-purple, 20%);
            color: #fff;

            .icon span::before {
                color: #fff;
            }
            .tree-control {
                color: #fff;
            }
        }
        .nodeChildren {
            margin-left: 1.125rem;
        }

    }

    .searchInputContainer {
        box-sizing: border-box;
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid $mid-purple;

        .searchInput {
            margin: 0;
            display: flex;
            height: 2.0rem;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;

            .search-icon-container {
                width: 1.5rem;
                height: 100%;
                text-align: center;
                cursor: pointer;

                .search-icon {
                    height: 100%;
                    width: 1rem;
                    display: inline-block;
                }
            }
            .input-container {
                display: block;
                flex: 1;
                
                input {
                    border: none;
                    padding: 0;
                    margin: 0;
                    line-height: 16px;
                    outline: none;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.drag-drop-zone.inside-drag-area {
    opacity: 0.7;
}


.sectionHeader {
    display: inline-flex;
    place-content: space-between;
    width: 100%;
    h3 {
        line-height: 0%;
        margin-left: 10px;
        color: $dark-purple;
        font-weight: 550;
    }
    min-height: 20px;

   .iconContainer {
        display: inline-flex;
        span {
            margin-top: 12px;
            cursor: pointer;
        }
    }
}
.expandIcon {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
}
.expandIcon.smaller {
    height: 1rem;
    width: 1rem;
}

