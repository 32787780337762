.btn {
    margin: 0;
    padding: 13px;
    font-size: 1.0rem;
    line-height: 1.0rem;
    border: 1px solid #c5c7d4;
    border-radius: 5px;
    align-self: center;
    background: transparent;
    display: inline-block;
    color: $dark-purple;
    width: fit-content;
    text-transform: none;
    text-decoration: none;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    img {
        height: 0.9rem;
        width: 0.9rem;
        padding: 0 5px;
    }
    .icon {
        font-size: 0.9rem;
        margin-right: 0.5rem;
    }

    &:hover {
        background: $light-gray;
        border: 1px solid $dark-purple;
    }
}
.btn.btn-resource {
    display: block;
    padding: 3px;
    border: 1px solid #333;
    background: $dark-purple;
    color: $light-blue;
    cursor: pointer;
    text-align: center;
    &:hover {
        background: $light-gray;
        color: $dark-purple;
    }
}

.btn.btn-danger {
    color: #de0000;
    border: 2px solid #de0000;
    font-weight: bold;

    &:hover {
        background: lighten(#de0000, 20%);
        color: #fff;
    }
}

.btn.btn-submit {
    color: $green-one;
    border: 2px solid $green-one;
    font-weight: bold;

    &:hover {
        background: $green-one;
        color: #fff;
    }
}

.btn.btn-primary {
    background: $dark-purple;
    color: #fff;
    border: 1px solid $dark-purple;
}

.btn.btn-icon {
    font-size: 1.25em;
    .icon, span {
        font-size: 1.25em;
    }
}

.btn.btn-disabled {
    background: #888;
    color: #444;
    border: 0;
    &:hover {
        background: #888;
        color: #444;
        border: 0;
    }
}

.btn.full-width {
    width: 100%;
}
