.sceneEditorBottomContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .tabBarContainer {
        height: 30px;
        .tab {
            border-color: $mid-purple;
            border-right: none;
        }
    }
    .tabpanel {
        height: 100%;
        overflow-y: auto;
        .controlPanelInterior {
            overflow-y: auto;
            overflow-x: hidden;
            overflow: hidden;
        }
    }
}

.widgetSelector {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    
    .leftColumn {
        overflow-y: auto;
        display: grid;
        grid-template-rows: auto auto 1fr;
        width: 200px;
        background: $light-gray;
        color: $dark-gray;
        h3 {
            font-weight: 400;
            line-height: 0.3rem;
            padding-left: 5px;
        }
        .categories {
            .category {
                padding: 5pt;
                display: grid;
                grid-template-columns: auto 1fr;
                place-content: center;
                color: $dark-gray;
                cursor: pointer;
                font-weight: normal;
            }
            .category.selected {
                background: $mid-dark-gray;
                color: #fff;
                font-weight: bold;
            }

            .category.selected.focused {
                background: $light-blue;
            }
        }
        img.category-icon {
            width: 25px;
            height: 25px;
            margin: auto;
            margin-right: 5pt;
        }
    }

    .widgetSelectGridContainer {
        flex: 1;
        overflow-y: auto;
        height: 100%;
        background: $mid-dark-gray;

        .widgetSelectGrid {
            display: grid;
            grid-template-columns: repeat(auto-fit, 150px);
            grid-template-rows: repeat(auto-fit, 70px, 1fr);
            background: $mid-dark-gray;
            color: $mid-purple;
            padding: 10pt;
            min-height: 100%;
            box-sizing: border-box;

            .folders-list{
                grid-column: 1 / -1;
                margin: 0 !important;
                display: grid;
                grid-template-columns: repeat(auto-fit, 140px);
                gap: 10px;
                padding: 5px;

                .folder{
                    margin: 0 !important;
                    width: 100% !important;
                }

                .folder.active{
                                    grid-column: 1 / -1;
                    width: 100% !important;
                }
            }
        }
        .widgetSelectGrid.focused {
            border: 10px solid $light-blue;
        }

        .resourceContent {
            .btn-icon {
                padding: 7px;
            }
            background-color: $light-gray;
        }
    }

    h4 {
        margin: 5px 0;
    }
    .widgetCard {
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        height: 120px;
        position: relative;
        grid-template-rows: 1fr auto;
        place-content: center;
        text-align: center;
        border-radius: 3px;
        background: $light-gray;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        .widget-name {
            line-height: 25px;
            height: 50px;
            margin: 0em;
            p {
                display: inline-block;
                vertical-align: middle;
                line-height: 16px;
                margin: 0;
            }
        }
        .truncate-resource {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            inline-size: 120px;
        }
        .widget-info {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 25px;
            height: 25px;
            background: #fff;
            border-radius: 25px;
        }
        .widget-name:hover {
            position: absolute;
            z-index: 1000;
            background: #f0f1f1;
            border-radius: 3px;
            padding: 0.25rem;
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
            display: inline-block;
            left: 0;
            min-width: 100%;
            .truncate-resource {
                white-space: break-spaces;
                overflow: auto;
                text-overflow: initial;
                inline-size: auto;
            }
        }
    }
    .widgetCard:hover {
        background: darken($light-gray, 20%);
    }
    img.widget-icon {
        width: 100%;
        object-fit: contain;
        margin: 0;
        margin-top: 5px;
    }

    .infoIcon {
        position: absolute;
        top: 0.25em;
        right: 0.5em;
    }
    .icon-container {
        box-sizing: border-box;
        height: 90px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: auto 75%;
        border-radius: 5px;
    }
    .last-updated {
        display: none;
        color: $mid-dark-gray;
        font-size: 14px;
        font-style: italic;
    }
}

.list-view {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    .widgetCard {
        height: 75px;
         .widget-card-content {
            display: flex;

            .icon-container {
                max-width: 100px;
                height: 75px;
            }
            .widget-name {
                flex: 1;
                p {
                    font-size: 16px;
                    font-weight: bold;
                    text-overflow: initial;
                    width: 100%;
                    text-align: left;
                    margin-left: 25px;
                    padding-top: 20px;
                    .last-updated {
                        display: block;
                        font-weight: normal;
                        padding-top: 5px;
                    }
                }

                .truncate-resource {
                    white-space: normal;
                    overflow: auto;
                    text-overflow: initial;
                    inline-size: auto;
                }
            }
            .widget-name:hover {
                position: relative;
                width: auto;
                box-shadow: none;
            }
        }
    }
}

@media (min-width: 1500px) {
    .list-view {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}