.fileView {
    table {
        width: 100%;

        td.thumbnailCell {
            width: 100px;
            height: 80px;
            display: grid;
            place-content: center;
            margin: auto;
            border: 0;
            img {
                height: 80px;
                max-width: 100px;
            }
        }

        tr {
            td {
                padding: 5px;
                word-break: break-all;
            }
        }
    }
}
.selector {
    margin: 10px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    height: 50px;
    font-size: 12pt;
    border: 0;
    width: 150px;
}
