div.dropdown {
    position: relative;
    display: inline-block;
    ul.dropdownContent {
        visibility: hidden;
        position: absolute;
        bottom: 20px;
        right: 20px;
        border-radius: 3px;
        width: 180px;
        background: white;
        padding-inline-start: 0;

        li, a {
            list-style-type: none;
            display: flex;
            align-items: center;
            height: 32px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            color: #000;
            span {
                height: 15px;
                width: 15px;
                margin: 0px 15px 0px 15px;
                font-size: large;
                font-size: -webkit-large;
            }
        }
        li:first-child {
            border-radius: 3px 3px 0px 0px;
        }
        li:last-child{
            border: 1px solid $light-gray;
            border-radius: 0px 0px 3px 3px ;
        }

        li:hover {
            background: $light-purple;
            span:before {
                color: $light-blue;
            }
            p {
                color: $light-blue;
            }
        }
    }

    ul.dropdownContent.show {
        visibility: visible;
        z-index: 101;

        li {
            z-index: 101;
        }
    }
}