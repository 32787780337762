$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "/fonts" !default;

$icon-ProViz_Studio_Header_Logo: "\e900";
$icon-translate: "\e901";
$icon-share: "\e902";
$icon-downArrow: "\e903";
$icon-slash: "\e904";
$icon-scale: "\e905";
$icon-rotate: "\e906";
$icon-finishes: "\e907";
$icon-background: "\e908";
$icon-transform: "\e909";
$icon-redo: "\e90a";
$icon-undo: "\e90b";
$icon-trash_grey: "\e90c";
$icon-pencil: "\e90d";
$icon-outlined_star: "\e90e";
$icon-duplicate: "\e90f";
$icon-open: "\e910";
$icon-cancel-path1: "\e911";
$icon-cancel-path2: "\e912";
$icon-cancel-path3: "\e913";
$icon-yellow_star: "\e914";
$icon-folder-icon: "\e915";
$icon-hand-icon: "\e916";
$icon-object-icon: "\e917";
$icon-presentation-icon: "\e918";
$icon-ProViz-Studio-Assets-66: "\e919";
$icon-ProViz-Studio-Assets-67: "\e91a";
$icon-ProViz-Studio-Assets-68: "\e91b";
$icon-eye-2-icon: "\e91c";
$icon-eye-icon: "\e91d";
$icon-locked-icon: "\e91e";
$icon-ProViz-Studio-Assets-58: "\e91f";
$icon-ProViz-Studio-Assets-41-path1: "\e920";
$icon-ProViz-Studio-Assets-41-path2: "\e921";
$icon-ProViz-Studio-Assets-41-path3: "\e922";
$icon-ProViz-Studio-Assets-41-path4: "\e923";
$icon-ProViz-Studio-Assets-41-path5: "\e924";
$icon-ProViz-Studio-Assets-41-path6: "\e925";
$icon-ProViz-Studio-Assets-42-path1: "\e926";
$icon-ProViz-Studio-Assets-42-path2: "\e927";
$icon-ProViz-Studio-Assets-42-path3: "\e928";
$icon-ProViz-Studio-Assets-42-path4: "\e929";
$icon-ProViz-Studio-Assets-42-path5: "\e92a";
$icon-ProViz-Studio-Assets-42-path6: "\e92b";
$icon-ProViz-Studio-Assets-42-path7: "\e92c";
$icon-ProViz-Studio-Assets-42-path8: "\e92d";
$icon-ProViz-Studio-Assets-42-path9: "\e92e";
$icon-ProViz-Studio-Assets-42-path10: "\e92f";
$icon-ProViz-Studio-Assets-43-path1: "\e930";
$icon-ProViz-Studio-Assets-43-path2: "\e931";
$icon-ProViz-Studio-Assets-43-path3: "\e932";
$icon-ProViz-Studio-Assets-43-path4: "\e933";
$icon-ProViz-Studio-Assets-43-path5: "\e934";
$icon-ProViz-Studio-Assets-43-path6: "\e935";
$icon-ProViz-Studio-Assets-43-path7: "\e936";
$icon-ProViz-Studio-Assets-43-path8: "\e937";
$icon-ProViz-Studio-Assets-17: "\e938";
$icon-ProViz-Studio-Assets-21-path1: "\e939";
$icon-ProViz-Studio-Assets-21-path2: "\e93a";
$icon-ProViz-Studio-Assets-22-path1: "\e93b";
$icon-ProViz-Studio-Assets-22-path2: "\e93c";
$icon-ProViz-Studio-Assets-22-path3: "\e93d";
$icon-ProViz-Studio-Assets-22-path4: "\e93e";
$icon-ProViz-Studio-Assets-22-path5: "\e93f";
$icon-ProViz-Studio-Assets-22-path6: "\e940";
$icon-ProViz-Studio-Assets-22-path7: "\e941";
$icon-ProViz-Studio-Assets-22-path8: "\e942";
$icon-ProViz-Studio-Assets-22-path9: "\e943";
$icon-ProViz-Studio-Assets-22-path10: "\e944";
$icon-ProViz-Studio-Assets-22-path11: "\e945";
$icon-ProViz-Studio-Assets-22-path12: "\e946";
$icon-ProViz-Studio-Assets-22-path13: "\e947";
$icon-ProViz-Studio-Assets-22-path14: "\e948";
$icon-ProViz-Studio-Assets-22-path15: "\e949";
$icon-ProViz-Studio-Assets-22-path16: "\e94a";
$icon-ProViz-Studio-Assets-22-path17: "\e94b";
$icon-ProViz-Studio-Assets-22-path18: "\e94c";
$icon-ProViz-Studio-Assets-23-path1: "\e94d";
$icon-ProViz-Studio-Assets-23-path2: "\e94e";
$icon-ProViz-Studio-Assets-23-path3: "\e94f";
$icon-ProViz-Studio-Assets-23-path4: "\e950";
$icon-ProViz-Studio-Assets-23-path5: "\e951";
$icon-ProViz-Studio-Assets-23-path6: "\e952";
$icon-ProViz-Studio-Assets-23-path7: "\e953";
$icon-ProViz-Studio-Assets-23-path8: "\e954";
$icon-ProViz-Studio-Assets-23-path9: "\e955";
$icon-ProViz-Studio-Assets-24-path1: "\e956";
$icon-ProViz-Studio-Assets-24-path2: "\e957";
$icon-ProViz-Studio-Assets-24-path3: "\e958";
$icon-ProViz-Studio-Assets-24-path4: "\e959";
$icon-ProViz-Studio-Assets-24-path5: "\e95a";
$icon-ProViz-Studio-Assets-24-path6: "\e95b";
$icon-ProViz-Studio-Assets-24-path7: "\e95c";
$icon-ProViz-Studio-Assets-24-path8: "\e95d";
$icon-ProViz-Studio-Assets-24-path9: "\e95e";
$icon-ProViz-Studio-Assets-24-path10: "\e95f";
$icon-ProViz-Studio-Assets-24-path11: "\e960";
$icon-ProViz-Studio-Assets-24-path12: "\e961";
$icon-ProViz-Studio-Assets-25-path1: "\e962";
$icon-ProViz-Studio-Assets-25-path2: "\e963";
$icon-ProViz-Studio-Assets-25-path3: "\e964";
$icon-ProViz-Studio-Assets-25-path4: "\e965";
$icon-ProViz-Studio-Assets-25-path5: "\e966";
$icon-ProViz-Studio-Assets-25-path6: "\e967";
$icon-ProViz-Studio-Assets-25-path7: "\e968";
$icon-ProViz-Studio-Assets-25-path8: "\e969";
$icon-ProViz-Studio-Assets-25-path9: "\e96a";
$icon-ProViz-Studio-Assets-25-path10: "\e96b";
$icon-ProViz-Studio-Assets-25-path11: "\e96c";
$icon-ProViz-Studio-Assets-25-path12: "\e96d";
$icon-ProViz-Studio-Assets-25-path13: "\e96e";
$icon-ProViz-Studio-Assets-25-path14: "\e96f";
$icon-ProViz-Studio-Assets-26-path1: "\e970";
$icon-ProViz-Studio-Assets-26-path2: "\e971";
$icon-ProViz-Studio-Assets-26-path3: "\e972";
$icon-ProViz-Studio-Assets-26-path4: "\e973";
$icon-ProViz-Studio-Assets-26-path5: "\e974";
$icon-ProViz-Studio-Assets-26-path6: "\e975";
$icon-ProViz-Studio-Assets-26-path7: "\e976";
$icon-ProViz-Studio-Assets-26-path8: "\e977";
$icon-ProViz-Studio-Assets-26-path9: "\e978";
$icon-ProViz-Studio-Assets-26-path10: "\e979";
$icon-ProViz-Studio-Assets-26-path11: "\e97a";
$icon-ProViz-Studio-Assets-26-path12: "\e97b";
$icon-ProViz-Studio-Assets-27-path1: "\e97c";
$icon-ProViz-Studio-Assets-27-path2: "\e97d";
$icon-ProViz-Studio-Assets-27-path3: "\e97e";
$icon-ProViz-Studio-Assets-27-path4: "\e97f";
$icon-ProViz-Studio-Assets-27-path5: "\e980";
$icon-ProViz-Studio-Assets-27-path6: "\e981";
$icon-ProViz-Studio-Assets-28-path1: "\e982";
$icon-ProViz-Studio-Assets-28-path2: "\e983";
$icon-ProViz-Studio-Assets-28-path3: "\e984";
$icon-ProViz-Studio-Assets-28-path4: "\e985";
$icon-ProViz-Studio-Assets-28-path5: "\e986";
$icon-ProViz-Studio-Assets-28-path6: "\e987";
$icon-ProViz-Studio-Assets-28-path7: "\e988";
$icon-ProViz-Studio-Assets-28-path8: "\e989";
$icon-ProViz-Studio-Assets-30-path1: "\e98a";
$icon-ProViz-Studio-Assets-30-path2: "\e98b";
$icon-ProViz-Studio-Assets-30-path3: "\e98c";
$icon-ProViz-Studio-Assets-30-path4: "\e98d";
$icon-ProViz-Studio-Assets-30-path5: "\e98e";
$icon-ProViz-Studio-Assets-30-path6: "\e98f";
$icon-ProViz-Studio-Assets-30-path7: "\e990";
$icon-ProViz-Studio-Assets-30-path8: "\e991";
$icon-ProViz-Studio-Assets-31-path1: "\e992";
$icon-ProViz-Studio-Assets-31-path2: "\e993";
$icon-ProViz-Studio-Assets-31-path3: "\e994";
$icon-ProViz-Studio-Assets-31-path4: "\e995";
$icon-ProViz-Studio-Assets-31-path5: "\e996";
$icon-ProViz-Studio-Assets-31-path6: "\e997";
$icon-ProViz-Studio-Assets-31-path7: "\e998";
$icon-ProViz-Studio-Assets-31-path8: "\e999";
$icon-ProViz-Studio-Assets-32-path1: "\e99a";
$icon-ProViz-Studio-Assets-32-path2: "\e99b";
$icon-ProViz-Studio-Assets-32-path3: "\e99c";
$icon-ProViz-Studio-Assets-32-path4: "\e99d";
$icon-ProViz-Studio-Assets-32-path5: "\e99e";
$icon-ProViz-Studio-Assets-32-path6: "\e99f";
$icon-ProViz-Studio-Assets-32-path7: "\e9a0";
$icon-ProViz-Studio-Assets-32-path8: "\e9a1";
$icon-ProViz-Studio-Assets-33-path1: "\e9a2";
$icon-ProViz-Studio-Assets-33-path2: "\e9a3";
$icon-ProViz-Studio-Assets-33-path3: "\e9a4";
$icon-ProViz-Studio-Assets-33-path4: "\e9a5";
$icon-ProViz-Studio-Assets-33-path5: "\e9a6";
$icon-ProViz-Studio-Assets-33-path6: "\e9a7";
$icon-ProViz-Studio-Assets-33-path7: "\e9a8";
$icon-ProViz-Studio-Assets-33-path8: "\e9a9";
$icon-ProViz-Studio-Assets-34-path1: "\e9aa";
$icon-ProViz-Studio-Assets-34-path2: "\e9ab";
$icon-ProViz-Studio-Assets-34-path3: "\e9ac";
$icon-ProViz-Studio-Assets-34-path4: "\e9ad";
$icon-ProViz-Studio-Assets-34-path5: "\e9ae";
$icon-ProViz-Studio-Assets-34-path6: "\e9af";
$icon-ProViz-Studio-Assets-34-path7: "\e9b0";
$icon-ProViz-Studio-Assets-34-path8: "\e9b1";
$icon-ProViz-Studio-Assets-35-path1: "\e9b2";
$icon-ProViz-Studio-Assets-35-path2: "\e9b3";
$icon-ProViz-Studio-Assets-35-path3: "\e9b4";
$icon-ProViz-Studio-Assets-35-path4: "\e9b5";
$icon-ProViz-Studio-Assets-35-path5: "\e9b6";
$icon-ProViz-Studio-Assets-36-path1: "\e9b7";
$icon-ProViz-Studio-Assets-36-path2: "\e9b8";
$icon-ProViz-Studio-Assets-36-path3: "\e9b9";
$icon-ProViz-Studio-Assets-36-path4: "\e9ba";
$icon-ProViz-Studio-Assets-36-path5: "\e9bb";
$icon-ProViz-Studio-Assets-36-path6: "\e9bc";
$icon-ProViz-Studio-Assets-36-path7: "\e9bd";
$icon-ProViz-Studio-Assets-37-path1: "\e9be";
$icon-ProViz-Studio-Assets-37-path2: "\e9bf";
$icon-ProViz-Studio-Assets-37-path3: "\e9c0";
$icon-ProViz-Studio-Assets-37-path4: "\e9c1";
$icon-ProViz-Studio-Assets-37-path5: "\e9c2";
$icon-ProViz-Studio-Assets-37-path6: "\e9c3";
$icon-ProViz-Studio-Assets-37-path7: "\e9c4";
$icon-ProViz-Studio-Assets-38-path1: "\e9c5";
$icon-ProViz-Studio-Assets-38-path2: "\e9c6";
$icon-ProViz-Studio-Assets-38-path3: "\e9c7";
$icon-ProViz-Studio-Assets-38-path4: "\e9c8";
$icon-ProViz-Studio-Assets-38-path5: "\e9c9";
$icon-ProViz-Studio-Assets-38-path6: "\e9ca";
$icon-ProViz-Studio-Assets-38-path7: "\e9cb";
$icon-ProViz-Studio-Assets-38-path8: "\e9cc";
$icon-ProViz-Studio-Assets-38-path9: "\e9cd";
$icon-ProViz-Studio-Assets-38-path10: "\e9ce";
$icon-ProViz-Studio-Assets-38-path11: "\e9cf";
$icon-ProViz-Studio-Assets-39-path1: "\e9d0";
$icon-ProViz-Studio-Assets-39-path2: "\e9d1";
$icon-ProViz-Studio-Assets-39-path3: "\e9d2";
$icon-ProViz-Studio-Assets-39-path4: "\e9d3";
$icon-ProViz-Studio-Assets-39-path5: "\e9d4";
$icon-ProViz-Studio-Assets-39-path6: "\e9d5";
$icon-ProViz-Studio-Assets-39-path7: "\e9d6";
$icon-ProViz-Studio-Assets-39-path8: "\e9d7";
$icon-ProViz-Studio-Assets-40-path1: "\e9d8";
$icon-ProViz-Studio-Assets-40-path2: "\e9d9";
$icon-ProViz-Studio-Assets-40-path3: "\e9da";
$icon-share1: "\e9db";
$icon-ProViz-Studio-Assets-02: "\e9dc";
$icon-ProViz-Studio-Assets-03: "\e9dd";
$icon-ProViz-Studio-Assets-04: "\e9de";
$icon-ProViz-Studio-Assets-05: "\e9df";
$icon-ProViz-Studio-Assets-06-path1: "\e9e0";
$icon-ProViz-Studio-Assets-06-path2: "\e9e1";
$icon-ProViz-Studio-Assets-06-path3: "\e9e2";
$icon-ProViz-Studio-Assets-07-path1: "\e9e3";
$icon-ProViz-Studio-Assets-07-path2: "\e9e4";
$icon-ProViz-Studio-Assets-08-path1: "\e9e5";
$icon-ProViz-Studio-Assets-08-path2: "\e9e6";
$icon-ProViz-Studio-Assets-09: "\e9e7";
$icon-ProViz-Studio-Assets-10: "\e9e8";
$icon-ProViz-Studio-Assets-11: "\e9e9";
$icon-ProViz-Studio-Assets-12: "\e9ea";
$icon-ProViz-Studio-Assets-13: "\e9eb";
$icon-ProViz-Studio-Assets-14: "\e9ec";
$icon-ProViz-Studio-Assets-15: "\e9ed";
$icon-ProViz-Studio-Assets-16: "\e9ee";
$icon-person: "\e9ef";
$icon-search1: "\e9f1";
$icon-user: "\e9f0";
$icon-search: "\e9f2";
$icon-arrow-up: "\ea32";
$icon-arrow-up2: "\ea3a";
$icon-plus: "\ea0a";

