.flow {
  width: 100%;
  height: 100%;
  position: relative;
  .react-flow {
    background: $darker-purple;
  }
  #root {
    display: flex;
    flex-direction: column;
  }

  .flow-node-header {
    background: $light-purple;
    color: #fff;
    border-radius: 0;
    height: 15px;
    font-size: 10px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    padding-left: 5px;
    padding: 5px;

    img.widget-icon {
      height: 15px;
      padding: 2px;
    }
  }

  .flow-node-preview {
    background-size: contain;
    width: 100%;
    height: calc(100% - 25px);
    opacity: 0.25;
  }

  .flow-node-subheader {
    // Used for animation service checkbox on model widget node
    height: 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    place-content: center;
    margin-top: 5px;
    font-size: 10px;

    div.checkbox {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      margin-left: 5px;
      margin-top: 8px;
      font-size: 12px;
    }
  }
  .flow-node-header.selected {
    background: $light-blue;
  }
  .flow-note {
    position: absolute;
    width: 180px;
    color: white;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.3);
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    padding: 8pt;
    font-size: 8pt;
    z-index: 5000;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .flow-note.selected {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  header {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    font-weight: 700;
    align-items: center;
  }

  .logo {
    text-decoration: none;
    display: block;
    line-height: 1;
  }

  header a,
  header a:focus,
  header a:active,
  header a:visited {
    color: #111;
  }

  header a:hover {
    color: #333;
  }

  header select {
    margin-left: auto;
  }

  .overview-example__add {
    display: none;
  }

  .react-flow__node a {
    font-weight: 700;
    color: #111;
  }

  .react-flow__node.dark-node {
    background: #0041d0;
    color: #f8f8f8;
  }

  .react-flow__node.dark {
    background: #557;
    color: #f8f8f8;
  }

  // .react-flow__node-selectorNode {
  //   font-size: 12px;
  //   background: #f0f2f3;
  //   border: 1px solid 555;
  //   border-radius: 5px;
  //   text-align: center;
  // }

  .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
    div {
      animation: none;
    }
  }

  @keyframes bounce {
    0% {
      transform: translate(0, -50%) scale(1);
    }
    50% {
      transform: translate(0, -50%) scale(1.3);
    }
  }

  .react-flow__node-selectorNode .react-flow__handle {
    border-color: #f0f2f3;
  }

  .flowMenuButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    background: $light-gray;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    width: 25px;
    height: 25px;
    margin: auto;
    margin-right: 5pt;
    &:hover {
      background: $mid-gray;
    }
    &.flowToolbarButton {
      top: 50px;
    }
  }
}

.contextMenu {
  position: absolute;
  width: 200px;
  background: #181818;
  // padding: 10px;
  border-radius: 6px;
  z-index: 100;
  height: 300px;
  overflow: hidden;
  h2 {
    font-weight: 200;
    line-height: 1;
    color: $light-blue;
    background: $mid-purple;
    padding: 5px;
    margin: 0;
  }
  .flowWidgetList {
    overflow-y: auto;
    height: 266px;
  }
  .flowWidgetEntry {
    cursor: pointer;
    padding-left: 8px;
    border: 0.5px solid $mid-purple;
    &:hover {
      background: $mid-gray;
    }
  }
}
.textureThumbnail {
  height: 25px;
  width: 25px;
}

.edgebutton {
  width: 100%;
  height: 100%;
  background: #eee;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 0;
  background: transparent;
  color: #fff;
  font-weight: bold;
  display: block;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
}
.edge-body {
  background: transparent;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note {
  position: absolute;
  border: 0;
  border-radius: 0;
  .note-desc {
    display: none;
    position: absolute;
    bottom: 35px;
    left: -5px;
    width: 180px;
    color: white;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.3);
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
    padding: 8pt;
    font-size: 8pt;
    z-index: 5000;
    pointer-events: none;
  }
  &:hover .note-desc {
    display: block;
  }
}

// .flow-node-model {
//   min-width: 25rem;
// }