@import "./modelviewersettings/_modelViewerSettings.scss";

@import "./variantpanel/_variantPanel.scss";

@import "./variantpanel/_collapsableMenuGroup.scss";

div.modelEditorContainer {
  display: flex;
  height: 100%;
  top: 75px;
  position: relative;

  .canvasContainer {
    flex: 4;
    position: relative;

    .leftButtonContainer {
      position: absolute;
      top: 20px;
      left: 10px;
      display: flex;
      flex-direction: column;
      z-index: 200;
      border-radius: 10px;
    }

    .transformControls {
      position: absolute;
      top: 20px;
      right: 10px;
      display: flex;
      flex-direction: column;
      z-index: 200;
      border-radius: 10px;

      span {
        height: 36px;
        width: 36px;
        border-radius: 5px;
        border: 1px solid $mid-purple;
        background: $light-gray;
        font-size: xx-large;
        font-size: -webkit-xx-large;
        padding: 2px;
        text-align: center;
      }

      img.selected {
        background: #fff;
      }
    }
  }
  .rightControls {
    width: 300px;
  }
}
.controlPanelInterior {
  padding: 10px;
  h2 {
    color: $mid-purple;
    font-weight: 400;
    line-height: 0;
    padding-bottom: 18px;
    border-bottom: 3px solid $mid-purple;
  }
  p {
    // line-height: 0;
    margin-left: 10px;
    color: $dark-gray;
    font-size: small;
  }
  h6 {
    line-height: 0;
    font-weight: 300;
    font-size: medium;
    margin: 20px 0px;
  }

  .centering-box {
    width: 100%;
    display: grid;
    place-content: center;
  }

  button.reset {
    border-radius: 5px;
    background: $light-gray;
    border: 1px solid $dark-gray;
    width: 80%;
    margin: 24px;
    text-align: left;

    background: #fff;
    width: 80%;
    height: 24px;
    display: grid;
    place-items: center;
    cursor: pointer;
    &:hover {
      background: $light-gray;
    }
  }
  select {
    padding: 10px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
    font-size: 12pt;
    border: 0;
    height: 40px;
  }
}

div.modelInfoPanel {
  display: flex;
  flex-direction: column;
  input {
    width: 100px;
    background: $light-gray;
  }
}

.container {
  height: 100%;
}

.modelControlsPanel {
  width: 300px;
  // width of the space its given is set in threemanager and resize
  .tabpanel {
    height: 85%;
    overflow-y: auto;
    .controlPanelInterior {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

div.rightControls {
  display: flex;
  height: 100%;
}

div.controlPanelInterior.share {
  display: flex;
  flex-direction: column;
  div.qrContainer {
    display: grid;
    place-items: center;
  }
  pre {
    padding: 5px;
    background: $mid-gray;
    font-size: 0.8em;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
  }
}
div.textureColorBoxes {
  display: grid;
  grid-template-columns: auto auto;
}

div.variantSelectorContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  place-content: center;
  button {
    width: fit-content;
    margin: 0px;
  }
}

.annotation-block {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;

  .numeric {
    margin: 5px 0;
    input {
      width: 100%;
      padding: 5px;
      border: 1px solid #000;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }
}

canvas.selectMode {
  cursor: pointer;
}
