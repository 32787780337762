@import "./_fileView.scss";

@import "./statusmodal/_statusModal.scss";

@import "./_userMenu.scss";

.home {
  height: 100%;
  width: 100vw;

  button:hover {
    background: $light-gray;
    cursor: pointer;
  }
}

.loading-indicator {
  margin: 15px;
}

.homeContainer {
  display: grid;
  grid-template-columns: 6rem 1fr;
  height: 100%;
  width: 100%;

  div.homeRightView {
    background: #f7f8fa;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    box-sizing: border-box;
    position: relative;

    .homeContent {
      padding-top: 70px;
      height: 100%;
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      box-sizing: border-box;
      .homeContentContainer {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 3rem;
        box-sizing: border-box;
        .paddedContainer {
          padding: 15px;
        }
      }
    }

    .modelList {
      display: flex;
      flex-wrap: wrap;
    }

    .materialList {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      grid-template-rows: auto;
      padding-right: 30px;
    }
  }

  div.homeLeftView {
    background: #fff;
    border-right: 1px solid #ecf0f8;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
    }
    .marketing {
      margin-bottom: 70px;
      display: grid;
      place-items: center;
      h4 {
        text-align: center;
        line-height: 0em;
      }
      button:hover {
        background: $light-purple;
        cursor: pointer;
      }
    }
  }
}

.folders-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .folder {
    margin: 0 15px 15px 15px;
    border-radius: 3px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: calc(100% - 30px);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 8px 10px;
    background: #fff;
    .global {
      text-transform: uppercase;
      font-size: 0.5rem;
      padding: 0 1rem;
      top: 0; //-0.25rem;
      position: relative;
    }
    .icon {
      padding: 4px;
      margin-right: 5px;
    }
    &.active {
      background: #f7f7f7;
      padding: 0;
      background: none;
      box-shadow: none;
      flex-direction: row;
      .global {
        top: -0.5rem;
      }
      div {
        display: inline;
        margin: 0 15px 0 0;
        text-align: left;
      }
      .button,
      .current {
        border-radius: 3px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        padding: 8px 10px;
        background: #fff;
      }
      .button {
        width: 33px;
        text-align: center;
      }
      .current {
        flex: 1;
        background: $dark-purple;
        color: #fff;
        margin: 0;
      }
    }
  }
}

ul.viewSelector {
  width: 4rem;
  padding: 0;
  margin-bottom: 0;
  flex: 1;
  li.viewSelectOption {
    list-style-type: none;
    width: 4rem;
    height: 30px;
    margin: 3rem 15px;
    padding: 0;
    cursor: pointer;
    display: flex;
    position: relative;
    border: 0;
    box-sizing: border-box;
    border-right: 5px solid transparent;
    img,
    span {
      width: 30px;
      max-height: 30px;
      margin: 3px;
    }
    .label {
      position: absolute;
      top: 0;
      left: 100%;
      padding: 10px;
      z-index: 1000;
      background: #fff;
      display: none;
      border-radius: 3px;
      border: 1px solid $dark-purple;
    }

    &:hover .label {
      display: block;
    }
  }

  li.viewSelectOption.selected {
    border-right: 5px solid $dark-purple;
  }
  li.viewSelectOption.large {
    margin: 10px;
    width: 65px;
    height: 55px;
    img {
      width: 55px;
      height: 55px;
      max-height: 55px;
      margin: 0;
    }

    .label {
      display: none;
    }
  }
  li.viewSelectOption:first-child {
    margin-top: 0;
  }

  li.homeViewItem {
    list-style-type: none;
    border-radius: 3px;
    margin: 15px 0;
    padding: 10px 15px;
    cursor: pointer;
    background: #fff;
    box-shadow: 4px 4px 8px rgba(180, 180, 180, 0.1);
    display: flex;
    img {
      width: 25px;
      max-height: 25px;
      margin-right: 10px;
    }
  }

  li.homeViewItem.selected {
    background: $dark-purple;
    color: white;
    font-weight: bold;
  }
  li.homeViewItem:hover {
    background: lighten($dark-purple, 20%);
    color: white;
  }
}

ul.viewSelector.bottom {
  height: 60px;
  flex: none;
  font-size: 32px;
  color: $light-purple;
  .label {
    font-size: 16px;
    color: #000;
  }
}

.thumbnailCard {
  margin: 0 15px 30px 15px;
  border-radius: 3px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: calc(100% - 30px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.34;

  .thumbnailContainer {
    background: rgb(193, 193, 193);
    background: radial-gradient(
      circle,
      rgba(193, 193, 193, 1) 50%,
      rgba(162, 162, 162, 1) 100%
    );
    position: relative;
    box-sizing: border-box;
    flex: 1;
    border-radius: 3px 3px 0 0;
    .bg {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 3px 3px 0 0;
    }
    position: relative;
    .last-update {
      position: absolute;
      top: 5px;
      left: 5px;
      margin: 0;
      padding: 0;
      display: none;
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 10px;
        background: rgba(200, 200, 200, 0.5);
        padding: 3px 5px;
        font-style: italic;
        border-radius: 3px;
      }
    }
    .buttonsContainer {
      position: absolute;
      top: 5px;
      right: 5px;
      display: none;
    }
    &:hover .last-update,
    &:hover .buttonsContainer {
      display: block;
    }
    .global {
      position: absolute;
      left: 0.5rem;
      bottom: 0.5rem;
      text-transform: uppercase;
      color: #fff;
      font-size: 0.6rem;
      background: rgba(0, 0, 0, 0.2);
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }
    .bg.padded {
      background-size: 33%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .infoRow {
    display: flex;
    flex-direction: row;
    place-content: space-between;
    padding-top: 0;
    background: #fff;
    border-radius: 0 0 3px 3px;

    .leftInfo {
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      width: 100%;
      flex-direction: column;
      overflow-wrap: break-word;
      overflow: hidden;

      b,
      p {
        margin-top: 0;
        cursor: pointer;
        flex: 1;
        color: $dark-gray;
        white-space: nowrap;
        font-size: 10pt;
      }

      b {
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        overflow: hidden;
        font-weight: normal;
      }

      p {
        margin-bottom: 0;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;

      button {
        height: 35px;
        border: none;
        background: white;
        display: grid;
        place-items: center;
        margin-top: auto;
        margin-bottom: auto;
        margin: 12px;
        border-radius: 10px;
        span {
          font-size: large;
          font-size: -webkit-large;
        }
        img {
          height: 20px;
          width: 20px;
        }
      }

      button:hover {
        background: $light-gray;
      }
    }
  }
}

@media (min-width: 1920px) {
  .homeContainer {
    div.homeRightView {
      .thumbnailCard,
      .folder {
        width: calc(20% - 30px);
      }
    }
  }
}
@media (min-width: 1130px) and (max-width: 1920px) {
  .homeContainer {
    div.homeRightView {
      .thumbnailCard,
      .folder {
        width: calc(25% - 30px);
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1130px) {
  .homeContainer {
    div.homeRightView {
      .thumbnailCard,
      .folder {
        width: calc(33.33333% - 30px);
      }
    }
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  .homeContainer {
    div.homeRightView {
      .thumbnailCard,
      .folder {
        width: calc(50% - 30px);
      }
    }
  }
}

@media (min-width: 240px) and (max-width: 600px) {
  .homeContainer {
    div.homeRightView {
      .thumbnailCard,
      .folder {
        width: calc(100% - 30px);
      }
    }
  }
}

.thumbnailContainer {
  position: relative;
  .checkbox {
    position: absolute;
    top: 15px;
    right: 30px;
    opacity: 0;

    width: 15px;
    height: 15px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1.5px solid #fff;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 15px;
    right: 16.5px;
    width: 15px;
    height: 15px;
    border: 1.5px solid #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $dark-purple;
    border-radius: 2px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked ~ .checkmark {
    background-color: #fff;
  }

  img {
    height: 160px;
    margin: auto;
    max-width: 100%;
  }
}
.addCard {
  min-width: 270px;
  max-width: 300px;
  min-height: 200px;
  max-height: 270px;
  border-radius: 10px;
  background: white;
  margin: 15px;
  display: table;
  p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  &:hover {
    background: $light-purple;
    cursor: pointer;
    p {
      color: white;
    }
  }
}

.hidden-dropzone {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.hidden-dropzone.active {
  background: $light-gray;
  height: 100%;
  width: 100%;
}

.fullDragOverlay {
  display: none;
}

.fullDragOverlay.active {
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $light-gray;

  img {
    height: 40vh;
    width: 40vw;
  }
  h2 {
    text-align: center;
    color: $mid-purple;
  }
}

.modalOverlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $mid-gray;
  opacity: 0.8;
}

.modalOverlay.clear {
  background: none;
}

.modalOverlay.hidden {
  display: none;
}

.highlight {
  background-color: #5f2c82;
  background: linear-gradient(45deg, #49a09d, #5f2c82);
  width: 800px;

  table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    th,
    td {
      padding: 15px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    th {
      text-align: left;
    }

    thead {
      &:before {
        overflow: hidden;
        z-index: 10;
      }
      tr,
      th {
        background-color: #55608f;
        z-index: 10;
        &:before {
          overflow: hidden;
          z-index: 10;
          background-color: #55608f;
        }
      }
    }

    tbody {
      z-index: 5;
      &:before {
        overflow: hidden;
        z-index: 5;
      }
      tr {
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        overflow: hidden;
      }
      td {
        position: relative;
        &:hover {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: -9999px;
            bottom: -9999px;
            background-color: rgba(255, 255, 255, 0.1);
            z-index: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.provizInputBox {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
}

table.inner {
  width: 100%;
  tr {
    td {
      padding: 5px;
      input,
      select {
        width: 100%;
      }
    }
    td:first-child {
      text-align: right;
    }
  }
  button {
    width: 100%;
  }
}

div.versionEntry {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  button {
    height: 40px;
  }
}

div.fileEditModal {
  display: grid;
  grid-template-columns: auto auto;
  .nameInput {
    min-height: 25px;
    min-width: 300px;
  }

  .btn {
    border: 0;
    border-radius: 3px;
    min-width: 40px;
    line-height: 100%;
    align-self: center;
    background: #fff;
    font-size: 10pt;
    color: $dark-purple;
    padding: 15px 15px;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    text-decoration: none;

    &:hover {
      background: $light-gray;
    }
  }
}

.spriteModalHeader {
  display: flex;
  place-content: space-between;
  width: 70%;
}

div.thumbnail {
  grid-column: 1 / span 2;
}

.loginTitle {
  margin-top: 5rem;
  padding: 1.4rem;
}
