form.threevalentry {
    border: 1px solid $dark-purple;
    border-radius: 10px;
    height: 30px;
    width: 90%;
    display: grid;
    margin: auto;
    grid-template-columns: 1fr 1fr 1fr auto;
    place-content: center;

    // display
    .inputcell {
        position: relative;
        height: 30px;
        display: grid;
        place-content: center;
        padding-right: 10px;

        input {
            margin: 0;
            margin-right: 3px;
            ;
            margin-left: 3px;
            ;
            width: 100%;
            outline: none;
            border: none;
            -moz-appearance: textfield;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        label {
            position: absolute;
            right: 10px;
            color: $dark-purple;
            bottom: 5px;
        }
    }
}

.borderRight {
    border-right: 1px solid black;
}

.borderLeft {
    border-left: 1px solid black;
}

.checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    height: 30px;
    border: 1px solid $mid-purple;
    border-radius: 10px;

    .checkmark {
        width: 100%;
        height: 100%;
    }
}

.threevalinputrow {
    display: grid;
    grid-template-columns: 1fr auto;
}