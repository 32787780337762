@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.ttf')  format('truetype'); 
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.ttf') format('truetype');
    font-weight: 550;
    font-style: normal;
}