
.react-flow__node {
    .flow-handle {
        position: absolute;
        border: 0;
        border-radius: 0;
        .desc {
            display: none;
        }
        &:hover .desc {
            display: block;
            position: absolute;
            top: -30px;
            left: 0;
            background: #F3E779;
            white-space: nowrap;
            padding: 5px;
            font-size: 8px;
            z-index: 5000;
            pointer-events: none;
        }
    }

    .global-node {
        border-radius: 1rem;
        .flow-node-header  {
            background-color: #6B73A6;
            color: #fff;
        }
        .content {
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 10px;
            line-height: 3rem;
            background-color: #6B73A6;
            color: #fff;
            border-radius: 1rem;
        }
        .content.selected {
            font-weight: bold;
            background-color: #55D5F1;
        }
    }
    &.react-flow__node-event-pass {
        border-radius: 1rem !important  
    }
}