ul.modelViewerSettings {
    margin: 0;
    padding: 5px;
    padding-left: 10px;
    background: #fff;

    li {
        list-style: none;
    }

    form.threevalentry {
        display: inline-flex;
    }
}