.searchBar {
    height: 50px;
    display: flex;
    place-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    button {
        margin: 0;
    }
    img {
        height: 30px;
        border: 2px solid $mid-purple;
        border-radius: 10px;
        padding: 3px;
        cursor: pointer;
        &:hover {
            background: $dark-yellow;
        }
    }
    span.searchInput {
        display: flex;
        span {
            font-size: large;
            font-size: -webkit-large;
            align-self: center;
            border-radius: 10px;
            border: 2px solid $mid-purple;
            padding: 3px;
            height: 30px;
            width: 30px;
        }
    }
    > span {
        font-size: 16pt;
        align-self: center;
        border-radius: 10px;
        padding: 6px;
        background: #fff;
        box-shadow: 2px 2px 2px rgba(180, 180, 180, 50%);
        cursor: pointer;
    }
    input {
        border-radius: 10px;
        height: 34px;
        max-width: 400px;
        border: 2px solid $mid-purple;
        margin-right: 10px;
        box-sizing: border-box;
    }
    .buttonsContainer {
        display: flex;

        button {
            margin: 0;
        }
        button:hover, span:hover {
            background: $light-purple;
        }
        img {
            margin: 5px;
        }
    }
}


.homeContainer {
    .searchBar {
        height: 50px;
        display: flex;
        place-content: space-between;
        align-items: center;
        margin: 15px;

        button {
            margin: 0;
        }

        img {
            height: 30px;
            border: 2px solid $mid-purple;
            border-radius: 10px;
            padding: 3px;
            cursor: pointer;

            &:hover {
                background: $dark-yellow;
            }
        }

        span.searchInput {
            display: flex;

            span {
                font-size: large;
                font-size: -webkit-large;
                align-self: center;
                border-radius: 10px;
                border: 2px solid $mid-purple;
                padding: 3px;
                height: 30px;
                width: 30px;
            }
        }

        >span {
            font-size: 16pt;
            align-self: center;
            border-radius: 10px;
            padding: 6px;
            background: #fff;
            box-shadow: 2px 2px 2px rgba(180, 180, 180, 50%);
            cursor: pointer;
        }

        input {
            border-radius: 10px;
            height: 34px;
            max-width: 400px;
            border: 2px solid $mid-purple;
            margin-right: 10px;
        }

        .buttonsContainer {
            display: flex;

            button {
                margin: 0;
            }

            button:hover,
            span:hover {
                background: $light-purple;
            }

            img {
                margin: 5px;
            }
        }
    }
}
.searchBar .pagingControls {
    margin: 0 auto;
    div {
        display: flex;
        flex-direction: row;
        font-size: xx-large;
        box-shadow: none;
        span {
            cursor: pointer;
            display: block;
            font-size: 14pt;
            width: 25px;
            height: 25px;
            padding: 5px;
            line-height: 25px;
            margin: 5px;
            border-radius: 30px;
            text-align: center;
            box-shadow: none;
            color: $dark-purple;
            &:hover {
                background: darken($light-gray, 10%);
            }
        }

        span.inactive {
            color: $mid-dark-gray;
            cursor: default;
            &:hover {
              background: none;
            }
        }

        span.active {
            box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
            &:hover {
                background: darken($light-gray, 10%);
            }
        }
    }
}
.widgetSelector .widgetSelectGridContainer .resourceContent {
    .searchBar .pagingControls div {
        display: flex;
        flex-direction: row;
        font-size: xx-large;
        cursor: pointer;
        box-shadow: none;

        span {
            display: block;
            font-size: 14pt;
            width: 25px;
            height: 25px;
            padding: 5px;
            line-height: 25px;
            margin: 5px;
            border-radius: 30px;
            text-align: center;
            box-shadow: none;
            color: $dark-purple;

            &:hover {
                background: darken($light-gray, 10%);
            }
        }

        span.active {
            box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
        }
    }


}