.controlPanel div.nodePropertiesPanel {
  overflow-x: hidden;
  overflow-y: auto;
  background: $light-gray;
  box-sizing: border-box;
  padding: 5px;
}
.propertiesPanel {
  padding: 0;
  background: $light-gray;
  .propertiesHeader {
    display: flex;
    place-content: space-between;
    margin-bottom: 0.5rem;
  }
  ul {
    margin: 0;
    padding: 5px;
    padding-left: 10px;
    li {
      list-style: none;
    }
  }

  h3 {
    font-weight: 300;
    color: $mid-purple;
    margin: 0;
  }

  h4 {
    font-weight: 300;
    margin: 5px 0;
  }

  h5 {
    margin: 0px;
    margin-top: 15px;
    width: 100%;
    box-shadow: #eee 0px 15px 20px 0;
    text-align: center;
    font-weight: 300;
    color: $mid-purple;
    font-size: large;
  }

  div:first-child h5 {
    padding-top: 0;
    margin-top: 5px;
  }

  form.threevalentry {
    display: inline-flex;
    .inputcell input {
      background: $light-gray;
    }
  }

  .removeButton {
    margin: 5px;
    margin-top: 20px;
  }

  .videoSelector {
    select {
      padding: 5px;
      width: 100%;
      border-radius: 5px;
    }
    select.language-selector {
      width: auto;
      padding: auto;
      border-radius: 0px;
    }
  }

  .onOffSelect {
    display: block;
    .btn {
      font-weight: bold;
      font-size: 13.3333px;
      color: #ccc;
      background: #222;
      padding: 5px;
      cursor: pointer;
      text-align: center;
      width: 100%;
      border-radius: 5px;
      box-sizing: border-box;
      border-width: 2px;
      border-style: outset;
      border-color: -internal-light-dark(
        rgb(118, 118, 118),
        rgb(133, 133, 133)
      );
      border-image: initial;
    }
    .btn:hover {
      background: #555;
    }
    .btn.on {
      background: #6b73a6;
      color: #fff;
    }
    .btn.on:hover {
      background: #7b83b6;
    }
  }

  .renderNodeEntry {
    input {
      width: 25px;
    }
    .childNodes {
      margin-left: 10px;
    }
  }

  .numeric input {
    width: 100%;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.panoramaUpload {
  display: grid;
  place-content: center;
  img.textureThumbnail {
    max-height: 50px;
    width: 100%;
    cursor: pointer;
  }
}
.multiPanoramaUpload {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}

.event-table,
.property-table {
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    tr {
      border-bottom: 1px solid #000;
      td {
        padding: 5px;
        border-bottom: 1px solid #000;

        input {
          width: 100%;
          padding: 5px;
          border: 1px solid #000;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
    }
  }

  input.multi-language-input {
    width: 90%;
    padding: 5px;
  }
}

.event-table table {
  width: 400px;
}
.property-header {
  display: flex;
  place-content: space-between;
}

.collapsible-properties-header {
  display: inline-flex;
  place-content: space-between;
  width: 100%;
  border-bottom: 1px solid $dark-purple;

  img {
    margin-top: 0px;
    margin-right: 5px;
  }
}

div.nodePropertiesPanel {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

div.propertiesPanel {
  overflow-y: auto;
}

span.smallText {
  font-size: small;
}

.slideContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 4rem 1fr;
  input {
    border-radius: 3px;
  }
}

.slider {
  -webkit-appearance: none;
  width: 95%;
  height: 20px;
  background: $dark-gray;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 20px;
  background: $dark-purple;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 20px;
  background: $dark-purple;
  cursor: pointer;
}

.widget-select {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  background-color: $mid-purple;
  color: #fff;
  border-radius: 3px;
  margin: 2px;
  > div {
    display: flex;
    .widget-select-part {
      flex: 1;
    }
    .widget-select-remove {
      width: 50px;
      &:hover {
        background-color: lighten($color: $mid-purple, $amount: 10);
      }
    }
  }
}

.resetButton {
  margin-top: 1rem;
}
