
div.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-width: 100%;

    height: 80vh;
    max-height: 100%;
    overflow: auto;
    padding: 20px 50px 20px 50px;
    z-index: 1010;
    background: #fff;
    border-radius: 4px;
    opacity: 1;

    .modalHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        h1 {
            font-weight: 300;
            color: $mid-purple;
            font-size: x-large;
            font-size: -webkit-x-large;
            line-height: 0;
            // margin-top: 15px;
        }
        img {
            height: 30px;
            width: 30px;
            margin: 5px;
        }
        button {
            margin: 5px;
            background: #fff;
            font-size: large;
        }
        span {
            margin: 5px;
            background: #fff;
            font-size: xx-large;
            font-size: -webkit-xx-large;
        }
        .buttonContainer {
            display: flex;
            span, button, img {
                margin-top: 0px;
                height: 26px;
                width: 26px;
                padding: 3px;
                cursor: pointer;
            }
        }
        h6 {
            margin: 0.3rem;
        }
    }
    .modalBody {
        textarea {
            border-radius: 5px;
            width: 100%;
        }

    }
}

div.modal.hidden {
    display: none;
}