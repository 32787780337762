.tabContainer {
    display: flex;
    flex-direction: column;
    place-content: space-between;
}
.tabBarContainer.tabs2 {
    grid-template-columns: 1fr 1fr;
}
.tabBarContainer.tabs3 {
    grid-template-columns: 1fr 1fr 1fr;
}
.tabBarContainer.tabs4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.tabBarContainer {
    display: grid;
    height: 40px;
    width: 100%;

    .tab {
        background: #fff;
        color: black;
        display: grid;
        place-content: center;
        border: 1px solid $mid-purple;
        position: relative;
        cursor: pointer;
        span:before {
            color: $mid-purple;
        }

        span {
            height: 25px;
            font-size: x-large;
            font-size: -webkit-x-large;
        }
        &:hover {
            background: $light-gray
        }
        &:active {
            span:before {
                color: $light-blue;
            }
        }
        &.selected {
            background: $light-purple;
            span:before {
                color: $light-gray;
            }
        }

        .label {
            height: 80%;
            justify-content: center;
            font-size: large;

            &.selectedLabel {
                color: #fff;
                font-weight: 600;
            }
    
            &.unselectedLabel {
                color: $mid-purple;
            }
        }

        .tooltipText {
            visibility: hidden;
            width: 120px;
            background-color: #fff;
            text-align: center;
            padding: 5px 0;
            border: 1px solid $mid-purple;
            border-radius: 6px;
            font-size: smaller;
            position: absolute;
            right: 5px;
            bottom: -30px;
            z-index: 1;
        }
    }

    .tab:hover .tooltipText {
        visibility: visible;
    }
    .tooltipText:before {
        opacity:0;
        transition:.3s opacity;   
      }
}
