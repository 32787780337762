.activeOrganization {
    margin: 20px;
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
    color: rgb(165, 165, 165);
}

.activeOrganization:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}