$darker-purple: #303e63;
$dark-purple: #313E64;
$mid-purple: #47538C;
$light-purple: #6B73A6;
$light-blue: #55D5F1;
$green-one: #4DF9A3;
$green-two: #B0FE76;
$dark-yellow: #FFAF47;
$light-gray: #F0F1F1;
$mid-gray: #e5e5e5;
$mid-dark-gray: #adadad;
$dark-gray:#6d6e71;