.modal.file-select {
    width: 75%;
    position: absolute;

    .inner-container {
        width: 100%;
        height: 100%;
        z-index: 1012;
        position: relative;

        input {
            z-index: 1014;
        }

        .mode-select {
            height: 60px;
        }

        .content {
            position: absolute;
            top: 60px;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    .mode-select {
        display: flex;
        border-bottom: 1px solid #ccc;

        .option {
            flex: auto;
            padding: 20px;
            border-left: 1px solid #ccc;
            text-align: center;
        }

        .option:first-child {
            border-left: 0;
            border-radius: 4px;
        }

        .option.active {
            background: #313E64;
            color: #FFF;
        }
    }

    .content {
        padding: 20px;

        .header {
            height: 50px;
            width: 100%;
            margin-bottom: 20px;
            display: flex;

            .item {
                flex: auto;

                .paging {
                    line-height: 15px;
                    display: flex;
                    text-align: center;

                    div {
                        display: inline-table;
                    }
                }

                .file-upload {
                    display: flex;
                }

                .search {
                    width: 90%;
                    padding: 5px;
                    border-radius: 5px;
                    box-sizing: border-box;
                }
            }
        }

        select {
            height: 40px;
            padding-left: 15px;
            border: none;
            box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
            color: #313E64;
        }

          .files {
            position: absolute;
            top: 80px;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: auto;
            padding: 20px;

            table {
                width: 100%;

                img.tex-thumbnail {
                    height: 25px;
                    width: 25px;
                }

                tr {
                    td {
                        padding: 10px;
                        border-bottom: 1px solid #313E64;
                        color: #313E64;
                    }
                }
            }
        }
    }
    .select-button-column {
        width: 15%;
    }

    .date-column {
        width: 25%;
    }
}