.provizFormContainer {
    border-radius: 5px;
    
    .title {
        font-size: 18px;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .content {
        margin: 0;
        padding: 0;
        height: auto;
        background: transparent;
    }
}