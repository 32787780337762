.fullWidthSpaced {
    display: flex;
    place-content: space-between;
}

.statusLists {
    .listHeader {
        display: grid;
        grid-template-columns: 30px 150px 1fr 50px;
        place-items: center;
    }

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .modelEntry, .variantEntry {
        display: grid;
        grid-template-columns: 30px 150px 1fr 50px;
        place-items: center;
    }
    
    .files {
        background: $mid-gray;
        width: 100%;
        margin-left: -20px;
        padding-left: 60px;
        padding: 5px;
    }
    
    .fileEntry {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;

    }

    hr {
        color: $mid-purple;
    }

    .loglist {
        max-height: 400px;
        overflow: auto;
        background: $light-gray;
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}