.editorToolbar {
  //   position: absolute;
  padding: 0.4rem;
  top: 10px;
  left: 10px;
  z-index: 100;

  .iconContainer {
    display: inline-flex;
    height: 35px;

    img {
      cursor: pointer;
      padding: 5px;
      border-radius: 3px;
      width: 25px;
      height: 25px;
      margin: auto;
      margin-right: 5pt;
    }

    img.icon {
      background: #fff;

      &:hover {
        background: $mid-gray;
      }
    }
  }
}
.iconFullContainer {
  float: right;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ecf0f8;
  text-align: left;
  padding: 1rem;
}

tr:nth-child(even) {
  background-color: #ecf0f8;
}
