@import "./savebutton/_saveButton.scss";

.headerBar {
  display: flex;
  place-content: space-between;
  height: 75px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  div.authenticated {
    display: flex;
  }

  .homeLink {
    span {
      font-size: 28pt;
      display: block;
      line-height: 100%;
      margin: 8pt;
    }
    place-content: center;
    text-decoration: none;
    color: $dark-purple;
    display: inline-flex;
    justify-content: baseline;
  }

  &.scene-editor {
    padding: 10pt;
    box-sizing: border-box;
  }

  .buttonsContainer {
    display: flex;
    .historyControls {
      height: 40px;
      align-self: center;
      display: flex;
      button.unavailable {
        cursor: default;
        &:hover {
          color: #fff;
          background: #fff;
        }
      }
      button.active {
        background: $light-blue;
      }
      button.available {
        &:active {
          background: $light-blue;
        }
      }
      span.available:before {
        color: $dark-gray;
      }
      span.unavailable:before {
        color: $light-gray;
      }
      span.available {
        cursor: pointer;
        &:hover {
          color: $light-blue;
        }
      }
    }
  }
}

.divider {
  width: 15px;
}

.homeButton {
  margin-top: 0.14rem;
}
