.searchInput {
    margin: 16px;
    height: 40px;
    position: relative;
}
.searchInput span {
    padding: 8px;
    font-size: 26px;
    line-height: 26px;
    display: inline-block;
    width: 35px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: rgb(165, 165, 165);
}
.searchInput input {
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding-left: 45px;
    border: 0;
    cursor: pointer;
    background: transparent;
}