@import "./bottommenu/_bottomMenu.scss";

@import "./flow/_flow.scss";

@import "./flow/_widgetNode.scss";

@import "./nodeselector/_nodeselector.scss";

@import "./propertypanel/_propertiesPanel.scss";

@import "../modeleditor/variantpanel/_collapsableMenuGroup.scss";

.editorContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 75px;
  height: calc(100% - 60px);

  .interiorEditorContainer {
    width: 100%;
    height: 100%;

    .verticalEditorContainer {
      display: grid;
      grid-template-rows: 1fr auto;
    }
  }
  .editorAndFlowContainer {
    flex: 4;
    position: relative;
    display: flex;
  }

  .canvasContainer {
    flex: 4;
    position: relative;
    canvas {
      position: absolute;
    }
  }

  .flow-container {
    display: none;
  }
  .flow-container.split {
    display: inherit;
  }

  .controlPanel {
    height: calc(100vh - 60px);
    grid-template-rows: 1fr 2fr;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: auto;
    margin-left: 0px;
    z-index: 10;
  }

  button.panoramaSceneEditorButton {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 12px 6px;
    border: 1px solid #fff;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font: normal 13px sans-serif;
    text-align: center;
    cursor: point;
    opacity: 0.8;
    outline: none;
    z-index: 999;
    cursor: pointer;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }
}

.bottomPanel {
  overflow: hidden;
  z-index: 1000;
}

.widgetInfoPanel.resourceInfoPanel {
  height: fit-content;
}

.widgetInfoPanel {
  width: 300px;
  height: 150px;
  position: fixed;
  background: $mid-gray;
  color: $dark-gray;
  z-index: 1000;
  overflow: hidden;
  cursor: help;
  box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
  h3 {
    font-weight: 400;
    padding-left: 5px;
    color: $dark-purple;
    position: sticky;
    overflow: hidden;
    margin-top: 0px;
  }
  .widgetName {
    width: 100%;
    height: fit-content;
    overflow: hidden;
  }
  .widgetInfoText {
    padding-left: 5px;
    overflow: auto;
    height: 100%;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
}

.compatabilityTable {
  h4 {
    color: $dark-purple;
    font-weight: 300;
    font-size: x-large;
    line-height: 0px;
  }
  overflow: hidden;
  margin-bottom: 60px;
  td {
    border: 1px $dark-purple;
    padding-right: 5px;
  }
}

.split {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.split.vertical {
  flex-direction: column;
}

.gutter {
  background-color: #fefefe;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: col-resize;
  z-index: 2;
}
