div.editableTitle {
    display: flex;
    flex: 1;
    justify-content: center;
    input {
        color: $mid-purple;
        border: none;
        font-size: 14pt;
        height: 20pt;
        margin: 10pt;
        padding: 3pt;
        width: 100%;
        text-align: center;
        &:focus-visible, &:focus {
            border: 0;
            outline: 0;
            box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
        }
    }
}
