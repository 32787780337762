.file-upload-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    p {
        line-height: 0px;
    }
    div {
        display: inline-flex;
        place-content: space-between;
        width: fit-content;
    }
    .label {
        margin-right: 5px;
        font-size: 12px;
    }
    .failed {
        background: 'red';
    }
}