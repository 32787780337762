.pagingPages span {
    border: 0;
    font-size: 14pt;
    font-weight: lighter;
    box-shadow: none;
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding: 5px;
    margin: 5px;
    border-radius: 30px;
    text-align: center;
    color: $dark-purple;
    &:hover {
        background: darken($light-gray, 10%);
        font-weight: bold;
    }
}

.pagingPages span.pagination {
    background: #fff;
}

.homeContainer div.homeRightView .pagingControls .pagingPages span.active {
    border: 0;
    font-weight: bold;
    background: $light-gray;
    box-shadow: 2px 2px 2px rgba(180, 180, 180, 0.5);
}

.pagingPages span.active {
    border: 0;
    font-weight: bold;
    background: #fff;
    box-shadow: 2px 2px 2px rgba(150, 150, 150, 0.5);
}

