.textureBox {
    position: relative;
    height: 110px;
    border: 1px solid $dark-gray;
    width: 100px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 17  0px 50px;
    place-items: center;
    margin-top: 10px;
    cursor: pointer;

    img.texture, div.texture {
        width: 100px;
        height: 110px;
        border-radius: 10px 10px 0 0;
    }
    img.texture.default, div.texture.default{
        width: 80px;
    }

    .fileDropdown {
        height: 100px;
        width: 100px;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        border-radius: 3px;

        .fileDropdown-el {
            background: #fff;
            color: $light-blue;
            cursor: pointer;
            display: grid;
            place-content: center;
            text-align: center;
            img {
                height: 20px;
            }
        }
        .fileDropdown-el:hover {
            background: $dark-purple;
        }
    }

    .dropDown {
        height: 50px;
        width: 100px;
        border-radius: 0 0 10px 10px;
        display: grid;
        place-items: center;
        position: relative;

        img.icon {
            height: 50px;
            width: 50px;
            cursor: pointer;
        }

        div.materialDropdown {
            position: absolute;
            bottom: 20px;
            left: 20px;
            border-radius: 10px;
            width: 180px;
            border: 1px solid $dark-purple;
            background: $light-gray;
            display: grid;
            grid-template-columns: 50px 50px 50px;
            grid-gap: 1px;
            height: 100px;
            overflow: auto;
            z-index: 1001;

            div {
                border: 1px solid $dark-purple;
                cursor: pointer;
            }
            img {
                height: 50px;
                width: 50px;
                cursor: pointer;
            }
        }
    }
}

.propertiesPanel .colorSwatch {
    margin: 5px;
    margin-left: auto;
}

.colorSwatch {
    border: 2px solid $dark-gray;
    border-radius: 60px;
    height: 60px;
    width: 60px;
    margin: 30px;
    cursor: pointer;
}

.propertyRenderer {
    margin-left: auto;
}

.sketchPickerContainer {
    position: absolute;
    bottom: 20px;
    right: 60px;
    z-index: 1001;
}

/* When you mouse over the container, fade in the overlay icon*/
.textureBox:hover .overlay {
    opacity: 1;
}