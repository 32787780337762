div.loading-modal {
    display: grid;
    place-items: center;
    font-size: xx-large;
    img {
        max-height: 90%;
        max-width: 90%;
    }
    .loadingText {
        justify-content: center;
        text-align: center;
    }
}