.user-menu-container {
    position: relative;
    display: grid;
    place-content: center;
    .dropdown-panel {
        position: absolute;
        display: flex;
        top: 55px;
        right: 0;
        width: 150pt;
        border: 1px solid $mid-purple;
        padding: 5px;
        border-radius: 3px;
        background: #fff;
        flex-direction: column;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                div, a {
                    text-decoration: none;
                    color: #000;
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    padding: 5px;
                    box-sizing: border-box;
                    &:hover {
                        background: $light-gray;
                    }
                }
            }
        }
  
        pre {
            padding: 10px 15px;
            background: $mid-gray;
            font-size: .8em;
            margin-left: auto;
            margin-right: auto;
            overflow-x: auto;
        }
    }
}