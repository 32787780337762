.toggleFlip {
    display: flex;
    color: #fff;
    border-radius: 5px;
    border: 1px solid lighten($mid-purple, 20%);
    border-radius: 30px;

    div {
        flex: 1;
        padding: 0 3px;
        font-size: 1rem;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid transparent;
        line-height: 30px;
    }
    .circle {
        flex: none;
        aspect-ratio: 1;
        width: 28px;
        background: #ccc;
        border-radius: 30px;
        padding: 0;
        border: 2px solid #e3e3e3;
        margin: 0;
        .inner {
            flex: none;
            aspect-ratio: 1;
            background: #eee;
            width: 24px;
            border: 2px solid #eee;
            border-radius: 30px;
            padding: 0;
            margin: 0;
        }
    }
}
.toggleFlip.false {
    background: #ccc;
    > div:last-child {
        color: #999;
        flex: 3;
        border-radius: 0 30px 30px 0;
    }
    > div:first-child {
        display: none;
    }
}
.toggleFlip.true {
    background: $mid-purple;
    > div:last-child {
        display: none;
    }
    > div:first-child {
        flex: 3;
        background: $mid-purple;
        border-radius: 30px 0 0 30px;
    }
}